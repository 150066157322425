import * as Yup from 'yup'
import * as ValidationFunction from './validation'

export const PersonalDataValidationSchema = tls => {
  return Yup.object().shape({
    customerFirstName: ValidationFunction.customerFirstNameValidation,
    customerLastName: ValidationFunction.customerLastNameValidation,
    customerDateOfBirth: ValidationFunction.customerDateOfBirthValidation,
    customerCountryOfBirth: ValidationFunction.customerCountryOfBirthValidation,
    customerPlaceOfBirth: ValidationFunction.customerPlaceOfBirthValidation,
    customerBirthDepartment:
      ValidationFunction.customerBirthDepartmentValidation,
    customerSalutation: ValidationFunction.customerSalutationValidation,
    customerStreet: ValidationFunction.customerStreetValidation,
    customerHouseNumber: ValidationFunction.customerHouseNumberValidation,
    customerAddressField1: ValidationFunction.customerAddressField1Validation,
    customerAddressField2: ValidationFunction.customerAddressField2Validation,
    customerCity: ValidationFunction.customerCityValidation,
    customerPostalCode: ValidationFunction.customerPostalCodeValidation,
    customerPhoneCountryCode:
      ValidationFunction.customerPhoneCountryCodeValidation,
    customerTelephoneNr: ValidationFunction.customerTelephoneNrValidation,
    customerEmail: ValidationFunction.customerEmailValidation,
    customerEmailConfirmation:
      ValidationFunction.customerEmailConfirmationValidation,
    communicateByEmailConfirmation: ValidationFunction.communicateByEmail(tls),
  })
}

export const LicensePlateValidationSchema = Yup.object().shape({
  licensePlate: ValidationFunction.licensePlateValidation,
})

export const LicensePlateCurrentMileageValidationSchema = Yup.object().shape({
  licensePlate: ValidationFunction.licensePlateValidation,
  currentMileage: ValidationFunction.currentMileageValidation,
})

export const ContinueLaterValidationSchema = Yup.object().shape({
  continueLaterFirstName: ValidationFunction.customerFirstNameValidation,
  continueLaterLastName: ValidationFunction.customerLastNameValidation,
  continueLaterEmail: ValidationFunction.customerEmailValidation,
  continueLaterPhone: ValidationFunction.customerTelephoneNrValidation,
  continueLaterPhoneCountryCode: ValidationFunction.customerPhoneCountryCode,
  continueLaterDataProtection: ValidationFunction.agreeDataProtection,
})

export const ContinueLaterDealerValidationSchema = Yup.object().shape({
  continueLaterFirstName: ValidationFunction.customerFirstNameValidation,
  continueLaterLastName: ValidationFunction.customerLastNameValidation,
  continueLaterEmail: ValidationFunction.customerEmailValidation,
  continueLaterPhone: ValidationFunction.customerTelephoneNrValidation,
  continueLaterPhoneCountryCode: ValidationFunction.customerPhoneCountryCode,
})

export const TanAuthentificationValidationSchema = Yup.object().shape({
  tanAuthentification: ValidationFunction.tanAuthentificationValidation,
})

export const VehicleSearchValidationSchema = Yup.object().shape({
  model: ValidationFunction.ModelValidation,
  engine: ValidationFunction.EngineValidation,
  initialRegistrationDate: ValidationFunction.initialRegistrationDateValidation,
  currentMileage: ValidationFunction.currentMileageValidation,
})

export const DealerDataValidationSchema = Yup.object().shape({
  employeeLastName: ValidationFunction.employeeLastNameValidation,
  employeeFirstName: ValidationFunction.employeeFirstNameValidation,
  dealerCode: ValidationFunction.dealerCodeValidation,
  dealerEmail: ValidationFunction.dealerEmailValidation,
})

// eslint-disable-next-line max-len
export const DealerDataAndLicensePlateCurrentMileageValidationSchema = DealerDataValidationSchema.concat(
  LicensePlateCurrentMileageValidationSchema
)

export const CustomerHappinessIndexValidationSchema = Yup.object().shape({
  consent: ValidationFunction.agreeDataProtection,
})

import React from 'react'
import { ContentSection, Heading, Paragraph } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { Analytics, AnalyticsEvent } from '../../services/analytics'

const LinkExpiry = () => {
  const { t } = useTranslation()

  React.useEffect(() => {
    Analytics.track(new AnalyticsEvent.LinkExpiredPageView())
  }, [])

  const handleLink = () => {
    setTimeout(() => {
      Analytics.track(new AnalyticsEvent.ContinueLaterBackClick())
    }, Analytics.delay)
  }

  return (
    <ContentSection pageWrapSize="small">
      <ContentSection.ComponentWrapper>
        <Heading level="2">{t('linkExpiry:heading')}</Heading>
        <Paragraph>{t('linkExpiry:paragraph')}</Paragraph>
        <Paragraph>
          <a href={t('linkExpiry:hyperlink')} onClick={handleLink}>
            {t('linkExpiry:hyperlink')}
          </a>
        </Paragraph>
        <Heading level="4">{t('linkExpiry:farewellMessage')}</Heading>
      </ContentSection.ComponentWrapper>
    </ContentSection>
  )
}

export default LinkExpiry

import React from 'react'
import classNames from 'classnames'

export const HeroTeaserOpt = ({
  title,
  sub,
  imageUrl,
  imageSrcset,
  imgSizes,
  shallow,
  inverted,
  focusPart,
}) => {
  const classes = classNames(
    'c-hero-teaser',
    focusPart ? `c-hero-teaser--${focusPart}` : '',
    {
      'c-hero-teaser--inverted': inverted,
      'c-hero-teaser--shallow': shallow,
    }
  ).trim()

  return (
    <div className={classes}>
      <picture>
        <source srcSet={imageSrcset('image/webp')} type="image/webp" />

        <source srcSet={imageSrcset('image/jpeg')} type="image/jpeg" />

        <img
          className="c-hero-teaser__image"
          src={imageUrl}
          srcSet={imageSrcset('image/jpeg')}
          sizes={imgSizes}
          alt={title}
        />
      </picture>

      <div className="c-hero-teaser__content">
        <div className="c-hero-teaser__content-inner">
          <header className="c-hero-teaser__heading">
            <div className="c-hero-teaser__title">
              <h1>
                <span className="c-hero-teaser__title-row">{title}</span>
              </h1>
            </div>

            <div className="c-hero-teaser__subtitle">
              <p>{sub}</p>
            </div>
          </header>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { Spinner } from '@vwfs-bronson/bronson-react'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../routing'
import {
  getFormData,
  resetFormData as setTransactionId,
  setFormData,
} from '../../redux/features/form'
import { getDataStorage, done } from '../../redux/features/dataStorage'
import { createTransaction } from './transaction'
import { generateTanInit } from '../tan'
import { getAxiosToken, setAxiosIdToken, setAxiosToken } from '..'
import { mapBusModelToFrontendModel } from '../../data/mapping'

const WithTransaction = ({ transaction, children }) => {
  const dispatch = useDispatch()
  const dataStorage = useSelector(getDataStorage) || {}
  const formData = useSelector(getFormData) || {}

  const { transactionId } = formData

  const [isLoading, setLoading] = React.useState(true)
  useEffect(() => {
    try {
      if (Object.entries(dataStorage).length) {
        setLoading(false)
        return
      }
      if (!getAxiosToken()) {
        if (transaction || transactionId) {
          dispatch(setTransactionId(transaction || transactionId))
          generateTanInit(transaction || transactionId)
            .then(response => {
              setAxiosIdToken(response.response.nonce)
              setAxiosToken(response.response.token)
              dispatch(done(response.data.data))
              dispatch(
                setFormData({
                  ...mapBusModelToFrontendModel(response.data.data),
                  transactionId: transaction,
                })
              )
            })
            .catch(() => history.push('/error'))
        } else {
          createTransaction()
            .then(response => {
              dispatch(setTransactionId(response.transactionId))
              setAxiosIdToken(response.transactionId)
              setAxiosToken(response.token)
            })
            .catch(() => history.push('/error'))
        }
      }
    } catch (error) {
      dispatch(setTransactionId(null))
      history.push('/error')
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStorage])
  return (
    <>
      {isLoading && <Spinner className="u-m-xlarge" center />}
      {!isLoading && children}
    </>
  )
}

export default WithTransaction

/* eslint-disable no-shadow, react/no-array-index-key */

import {
  Button,
  ButtonContainer,
  Column,
  Layout,
  Spinner,
} from 'cj-common-components'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useBeforeunload } from 'react-beforeunload'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import moment from 'moment'
import {
  getStepData,
  setStepData,
  getFormData,
  setFormData,
} from '../../services/redux/features/form'
import { getDataStorage } from '../../services/redux/features/dataStorage'
import { Analytics, AnalyticsEvent } from '../../services/analytics'
import { dataChanged } from '../../services/data/mapping'
import formConfiguration from '../../components/Wizard/index.json'
import { saveJourneyData } from '../../services/api/journey/saveJourneyData'
import { getTransactionStatus } from '../../services/api/transaction/transaction'
import {
  getMaintenanceData,
  getMaintenancePlusData,
} from '../../services/redux/features/product'
import SpinnerElement from '../../components/SpinnerElement'
import JourneyWizard from '../../components/Wizard'

const Lazy = React.forwardRef((props, ref) => {
  const { step, isSubmitingCheck, setIsSubmiting, ...componentProps } = props
  const [component, setComponent] = React.useState(null)

  React.useLayoutEffect(() => {
    setComponent(null)
    setComponent(() => React.lazy(() => import(`./Step${step}/index.js`)))
  }, [step])

  if (component) {
    return React.createElement(component, {
      ...componentProps,
      ref,
      isSubmitingCheck,
      setIsSubmiting,
    })
  }
  return null
})

export function Form({
  match,
  history,
  setStepData,
  stepData,
  config,
  dataStorage,
  formData,
  maintenanceData,
  maintenancePlusData,
  setFormData,
}) {
  const [isSubmitingCheck, setIsSubmiting] = React.useState(false)

  const step = parseInt(match.params.step || 1, 10)
  const { t } = useTranslation()
  const stepRefs = {
    1: React.useRef(),
    2: React.useRef(),
    3: React.useRef(),
    5: React.useRef(),
  }

  useBeforeunload(e => {
    const currentData = { stepData: {} }
    currentData.stepData[step] = stepRefs[step].current.getData()
    if (step < 4 && dataChanged(dataStorage, merge(formData, currentData))) {
      e.preventDefault()
      const message = t('form:continueLater:closeTab:title')
      e.returnValue = message
      return message
    }
    return null
  })

  if (!step || step < 1 || step > 5 || step === 4) {
    history.push('/error')
    return null
  }

  const updateTypeofSale = () => {
    if (window.du_digitalData?.dealerData?.dealerCode) {
      return 'Partner sales (Dealer POS)'
    }
    if (
      window.du_digitalData?.product[0]?.attributes?.typeOfSale ===
      'Hybrid sales'
    ) {
      return 'Hybrid sales'
    }
    return 'Online sales'
  }

  return (
    <Fragment>
      <SpinnerElement visible={isSubmitingCheck} />
      <JourneyWizard step={step} />
      <React.Suspense
        fallback={<Spinner className="u-m-xlarge" center></Spinner>}
      >
        <Lazy
          ref={stepRefs[step]}
          step={step}
          data={stepData}
          isSubmitingCheck={isSubmitingCheck}
          setIsSubmiting={setIsSubmiting}
          onSubmit={async data => {
            setIsSubmiting(true)

            // This is run when "next" button is clicked in current step
            // Send analytics event on interaction
            switch (step) {
              // Continue clicked on product page
              case 1:
                Analytics.updateDefault({
                  product: [
                    {
                      attributes: {
                        recurringPayment: data.monthlyPrice,
                        duration: data.contractDuration,
                        yearlyMileage: data.contractMileage,
                        startDateOfContract: moment().format('YYYY-MM-DD'),
                      },
                    },
                  ],
                })
                Analytics.track(new AnalyticsEvent.ProductInformationProvided())
                break
              // Continue clicked on personal page
              case 2:
                Analytics.track(
                  new AnalyticsEvent.PersonalInformationProvided({
                    ...dataStorage,
                    ...formData,
                  })
                )
                break
              // Continue clicked on payment page
              case 3:
                Analytics.updateDefault({
                  product: [
                    {
                      attributes: {
                        typeOfSale: updateTypeofSale(),
                      },
                    },
                  ],
                })
                Analytics.track(new AnalyticsEvent.PaymentInformationProvided())
                break
              default:
            }

            // Set journeyData and formData
            const journeyData = cloneDeep(formData)
            journeyData.transactionStatus = getTransactionStatus(step + 1)

            journeyData.stepData[step] = {
              ...data,
              ...(step === 1 && {
                plan:
                  data.contractPlan === 'maintenance'
                    ? maintenanceData
                    : maintenancePlusData,
              }),
            }
            setFormData(journeyData)

            // Trigger next steps
            switch (step) {
              case 1:
              case 2:
                await saveJourneyData(journeyData)
                  .then(
                    () => history.push(`/form/${step + 1}`),
                    () => history.push('/error')
                  )
                  .catch(() => history.push('/error'))
                break
              case 3:
                // Don't navigate to next page, email
                // with continuation link is sent.
                break
              default:
                history.push(`/form/${step + 1}`)
                break
            }

            setIsSubmiting(false)
          }}
          setStepData={setStepData}
          config={config}
        >
          {({
            canGoForward,
            canGoBack,
            backName = t('form:back'),
            forwardName = t('form:continue'),
            goBackHandler,
          }) => (
            <>
              <Layout center>
                <Column span="1/1" s="1/1" center>
                  <ButtonContainer center>
                    {canGoBack ? (
                      <Button secondary fullWidth onClick={goBackHandler}>
                        {backName}
                      </Button>
                    ) : (
                      <Button secondary fullWidth disabled>
                        {backName}
                      </Button>
                    )}
                    {step < formConfiguration.steps.length && canGoForward ? (
                      <Button fullWidth type="submit">
                        {forwardName}
                      </Button>
                    ) : (
                      <Button fullWidth disabled type="submit">
                        {forwardName}
                      </Button>
                    )}
                  </ButtonContainer>
                </Column>
              </Layout>
            </>
          )}
        </Lazy>
      </React.Suspense>
    </Fragment>
  )
}

export default connect(
  (state, ownProps) => ({
    stepData: getStepData(state, ownProps.match.params.step || 1),
    formData: getFormData(state),
    dataStorage: getDataStorage(state),
    maintenanceData: getMaintenanceData(state),
    maintenancePlusData: getMaintenancePlusData(state),
  }),
  { setStepData, setFormData }
)(Form)

import { Card } from '@vwfs-bronson/bronson-react'
import {
  Column,
  Heading,
  ItemTeaser,
  Layout,
  Link,
  Paragraph,
  Section,
} from 'cj-common-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import imageMotorInsurance from '../../../../assets/images/confirmation-page-MotorInsurance.jpg'
import imagePayByPhone from '../../../../assets/images/confirmation-page-PayByPhone.png'
import CustomerHappinessIndex from '../../../../components/CustomerHappinessIndex'
import { Analytics, AnalyticsEvent } from '../../../../services/analytics'
import { PageName } from '../../../../services/analytics/enum'
import { setActualPage } from '../../../../services/redux/features/page'
import { qs } from '../../../../services/routing'
import './index.css'

export default function EsignSuccessful({ location }) {
  const { t } = useTranslation()
  const transactionId = qs(location.search, 'id')

  React.useEffect(() => {
    Analytics.track(new AnalyticsEvent.SuccessPageView())

    setActualPage(PageName.SUCCESS_PAGE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trackPhoneLinkClick = () => {
    Analytics.track(new AnalyticsEvent.SuccessPagePhoneClick())
  }

  const trackExitToAssurance = () => {
    Analytics.track(new AnalyticsEvent.SuccessPageExitToAssurance())
  }

  const trackExitToPayByPhone = () => {
    Analytics.track(new AnalyticsEvent.SuccessPageExitToPayByPhone())
  }

  const trackExitToDealerSearch = () => {
    Analytics.track(new AnalyticsEvent.SuccessPageExitToDealerSearch())
  }

  return (
    <React.Fragment>
      <Section pageWrap="small">
        <div className="u-text-center">
          <i className="c-icon c-icon--[semantic-success] u-text-success"></i>
        </div>
        <Heading headerType={1}>{t('confirmation:thankYou')}</Heading>

        <Paragraph>{t('confirmation:greetings:paragraph0')}</Paragraph>
        <Paragraph>{t('confirmation:greetings:paragraph1')}</Paragraph>
      </Section>

      <Section pageWrap="medium">
        <CustomerHappinessIndex transactionId={transactionId} />
        <hr className="u-m-none" />
      </Section>

      <Section pageWrap="small">
        <Paragraph>{t('confirmation:ifYouHaveQuestions')}</Paragraph>
        <Layout>
          <Column span="1/2" s="1/1">
            <ItemTeaser
              icon={t('confirmation:iconTelephone')}
              inline
              title={t('confirmation:telephone')}
              className="fr-fix-icon"
            >
              <a
                onClick={trackPhoneLinkClick}
                href={`tel:${t('confirmation:contactTelephone')}`}
              >
                {t('confirmation:contactTelephone')}
              </a>
              {t('confirmation:freeLine')}
              <span className="u-block">{t('confirmation:phonelineOpen')}</span>
            </ItemTeaser>
          </Column>
        </Layout>
      </Section>
      <Section pageWrap="small">
        <Layout>
          <Column span="1/1">
            <Paragraph>
              {t('confirmation:findNextServicePoint')}
              <a
                onClick={trackExitToDealerSearch}
                href={t('page:storeLocatorLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('confirmation:findNextServicePointLink')}
              </a>
            </Paragraph>
          </Column>
          <Column span="1/2" s="1/1">
            <Card
              onClick={trackExitToAssurance}
              imageSrc={imageMotorInsurance}
              title={t('confirmation:MotorInsuranceTitle')}
              subtitle={t('confirmation:MotorInsuranceText')}
              element="article"
            >
              <Link
                normalLink
                target="_blank"
                icon="semantic-forward"
                href={t('confirmation:MotorInsurance:link')}
              >
                {t('confirmation:MotorInsurance:buttonText')}
              </Link>
            </Card>
          </Column>
          <Column span="1/2" s="1/1">
            <Card
              onClick={trackExitToPayByPhone}
              imageSrc={imagePayByPhone}
              title={t('confirmation:PayByPhoneTitle')}
              subtitle={t('confirmation:PayByPhoneText')}
              element="article"
            >
              <Link
                normalLink
                target="_blank"
                icon="semantic-forward"
                href={t('confirmation:PayByPhone:link')}
              >
                {t('confirmation:PayByPhone:buttonText')}
              </Link>
            </Card>
          </Column>
        </Layout>
      </Section>
    </React.Fragment>
  )
}

import { axios } from '.'
import { getServiceBrandName } from '../brand'

const {
  REACT_APP_COUNTRYCODE,
  REACT_APP_PRODUCT,
  REACT_APP_ENTRYPOINT,
} = process.env

export async function trustedShopsQuestionnaireLink(transactionId) {
  const url = `/trusted-shops-integration-service/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}/questionnaire-link`
  const response = await axios.post(url, {
    custom: {
      type: 'checkout_serviceinspection',
    },
    transaction: {
      id: transactionId,
    },
  })
  return response.data.custom.redirectUrl
}

import { axios } from '.'
import { getServiceBrandName } from '../brand'

const {
  REACT_APP_COUNTRYCODE,
  REACT_APP_PRODUCT,
  REACT_APP_ENTRYPOINT,
} = process.env
/**
 * Validate IBAN
 *
 * @param {string} iban IBAN
 * @returns Validation result as boolean and additional payment data
 */
export function validateIban(iban, transactionId) {
  return axios
    .post(
      `/iban-validation/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}/${transactionId}`,
      { iban: formatIban(iban) },
      {
        validateStatus: statusCode => statusCode === 200 || 400,
      }
    )
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const formatIban = iban => {
  return iban ? iban.toUpperCase().replace(/\s+/g, '') : ''
}

import React, { useState } from 'react'
import { Formik } from 'formik'
import { Callout, Checkbox } from '@vwfs-bronson/bronson-react'
import { Button, Column, Layout, Paragraph } from 'cj-common-components'
import { useTranslation } from 'react-i18next'
import { trustedShopsQuestionnaireLink } from '../../services/api/trustedShops'
import { BrFormField } from '../FormField'
import { CustomerHappinessIndexValidationSchema } from '../../services/validation'
import './index.css'
import SpinnerElement from '../SpinnerElement'

export default function CustomerHappinessIndex({ transactionId }) {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Formik
      initialValues={{ consent: false }}
      validationSchema={CustomerHappinessIndexValidationSchema}
      onSubmit={async () => {
        setIsSubmitting(true)
        const link = await trustedShopsQuestionnaireLink(
          transactionId
        ).finally(() => setIsSubmitting(false))
        window.open(link, '_blank').focus()
      }}
    >
      {formikProps => (
        <>
          <SpinnerElement visible={isSubmitting} />
          <Callout title={t('customerHappinessIndex:title')}>
            <>
              <Paragraph>{t('customerHappinessIndex:text')}</Paragraph>
              <Layout center>
                <Column span="1/5" s="1/1" className="alignCheckboxRight">
                  <BrFormField
                    name="consent"
                    render={({ value }) => (
                      <Checkbox
                        checked={value}
                        onChange={e =>
                          formikProps.setFieldValue('consent', e.target.checked)
                        }
                      />
                    )}
                  />
                </Column>
                <Column span="4/5" s="1/1">
                  <Paragraph>{t('customerHappinessIndex:consent')}</Paragraph>
                </Column>
              </Layout>
              <Button onClick={formikProps.handleSubmit}>
                {t('customerHappinessIndex:button')}
              </Button>
            </>
          </Callout>
          <div className="o-page-wrap o-page-wrap--small">
            <Paragraph>
              {t('customerHappinessIndex:legalNotice:paragraph1')}
            </Paragraph>
            <Paragraph>
              {t('customerHappinessIndex:legalNotice:paragraph2:text')}
              <a
                href={t('customerHappinessIndex:legalNotice:paragraph2:link')}
                target="_blank"
                rel="noreferrer"
              >
                {t('customerHappinessIndex:legalNotice:paragraph2:link')}
              </a>
              .
            </Paragraph>
            <Paragraph>
              {t('customerHappinessIndex:legalNotice:paragraph3:text')}
              <a
                href={t('customerHappinessIndex:legalNotice:paragraph3:link')}
                target="_blank"
                rel="noreferrer"
              >
                {t('customerHappinessIndex:legalNotice:paragraph3:link')}
              </a>
              .
            </Paragraph>
            <Paragraph>
              {t('customerHappinessIndex:legalNotice:paragraph4:text')}
              <a
                href={`${process.env.PUBLIC_URL}${t(
                  'customerHappinessIndex:legalNotice:paragraph4:link'
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {t('customerHappinessIndex:legalNotice:paragraph4:linkText')}
              </a>
              .
            </Paragraph>
          </div>
        </>
      )}
    </Formik>
  )
}

export function downloadPdf(data, t, type) {
  let fileName = 'sepaMandateFileName'
  if (type !== 'SEPA') {
    fileName = 'bordereauFileName'
  }
  // IE workaround for transforming base64 string to pdf. Solution
  // found: https://stackoverflow.com/questions/27154606/saving-base64-encoded-pdf-with-internet-explorer-10-and-below
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const byteChars = atob(data.base64Pdf)
    const byteNumbers = new Array(byteChars.length)
    for (let i = 0; i < byteChars.length; i += 1) {
      byteNumbers[i] = byteChars.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const pdfBlob = new Blob([byteArray], {
      type: 'application/pdf',
    })
    window.navigator.msSaveOrOpenBlob(pdfBlob, t(`confirmation:${fileName}`))
  } else {
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${data.base64Pdf}`
    link.target = '_blank'
    link.download = t(`confirmation:${fileName}`)
    link.click()
  }
}

export const isWorkshop = (formData, dataStorage) =>
  !!(formData?.dealerData && dataStorage?.data === undefined)

export const pendingUploadedFile = values => {
  return !!values?.SEPADocument
}

export const scrollToElement = (selector, align = 'start', submitSelector) => {
  // get shopping cart height so that scrolling won't put the element
  // behind it
  const shoppingCart = document.querySelector('.c-floating-bar')
  const heightOffset =
    shoppingCart && shoppingCart.getBoundingClientRect().height + 20
  let alignmentHeight
  switch (align) {
    case 'center':
      alignmentHeight = window.innerHeight / 2
      break
    case 'end':
      alignmentHeight = window.innerHeight - window.innerHeight / 5
      break
    case 'start':
    default:
      alignmentHeight = 0
  }
  const element = document.querySelector(selector)
    ? document.querySelector(selector)
    : document.querySelector(submitSelector)

  if (element) {
    let labelOffset = 0
    if (element.tagName.toUpperCase() === 'INPUT') {
      const label = document.querySelector('.c-form-field__label')
      labelOffset = label && label.getBoundingClientRect().height
    }
    // get relative element position from top for scrolling
    const position = element.getBoundingClientRect().top
    if (/Edge/.test(navigator.userAgent)) {
      window.scrollTo(
        0,
        element.getBoundingClientRect().top + window.scrollY - labelOffset - 120
      )
    } else if (document.documentMode) {
      window.scrollTo(
        0,
        element.getBoundingClientRect().top +
          window.pageYOffset -
          labelOffset -
          120
      )
    } else {
      window.scrollBy({
        top: position - heightOffset - alignmentHeight - labelOffset,
        left: 0,
        behavior: 'smooth',
      })
    }
  }
}

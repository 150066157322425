import { Paragraph, Section, SectionHeading } from 'cj-common-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getLandingPage } from '../../../../services/redux/features/page'

export default function EsignRefused() {
  const { t } = useTranslation()

  const landingPage = useSelector(getLandingPage)

  return (
    <React.Fragment>
      <Section pageWrap="small">
        <SectionHeading center headerType={3}>
          {t('eSign:refused.heading')}
        </SectionHeading>
      </Section>
      <Section pageWrap="small">
        <Paragraph>{t('eSign:refused.subtitle')}</Paragraph>
      </Section>
      <Section pageWrap="small">
        <div className="u-text-center">
          <a
            className="c-btn"
            href={`${window.location.origin}/${landingPage}`}
          >
            {t('eSign:refused.backToMain')}
          </a>
        </div>
      </Section>
    </React.Fragment>
  )
}

import { mapFrontendModelToBftModel } from '../../data/mapping'
import { axios } from '../index'
import { getServiceBrandName } from '../../brand'

const {
  REACT_APP_COUNTRYCODE,
  REACT_APP_PRODUCT,
  REACT_APP_ENTRYPOINT,
} = process.env

export function saveJourneyData(data) {
  const { transactionId, transactionStatus, stepData } = data
  const url = `save/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}/${transactionId}`

  return axios
    .post(url, mapFrontendModelToBftModel(stepData, transactionStatus), {
      validateStatus: statusCode => statusCode === 200,
    })
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

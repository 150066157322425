import React from 'react'
import {
  FormField as BronsonReactFormField,
  ErrorMessage,
  InfoIcon,
} from '@vwfs-bronson/bronson-react'
import { FormField as CJFormField } from 'cj-common-components'
import { Field } from 'formik'
import get from 'lodash/get'

export function FormField({ render, name, label = '', hint }) {
  return (
    <div className="o-fieldset__row">
      <Field name={name}>
        {({ field, form }) => (
          <CJFormField
            label={label || ''}
            error={form.touched[name] && form.errors[name]}
            hint={hint}
          >
            {props =>
              render({
                name,
                isError: !!(form.touched[name] && form.errors[name]),
                ...field,
                ...props,
              })
            }
          </CJFormField>
        )}
      </Field>
    </div>
  )
}

export function FormFieldContainer({ children }) {
  return (
    <fieldset
      className="o-fieldset u-mb-large"
      style={{ position: 'relative' }}
    >
      {children}
    </fieldset>
  )
}

export function BrFormField({
  render,
  name,
  labelElement = 'label',
  labelText,
  hint,
  infoIcon,
  notion,
  type = 'input',
  className,
  testId,
  noFloatingLabel = false,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <BronsonReactFormField
          testId={testId}
          className={className}
          labelElement={labelElement || 'label'}
          labelText={
            <>
              {labelText}
              {notion && '*'}
              {infoIcon && <InfoIcon icon="semantic-info">{infoIcon}</InfoIcon>}
            </>
          }
          type={type}
          errorMessage={
            get(form.errors, name) && get(form.touched, name) ? (
              <ErrorMessage>
                {get(form.touched, name) && get(form.errors, name)}
              </ErrorMessage>
            ) : (
              ''
            )
          }
          hint={hint}
          noFloatingLabel={noFloatingLabel}
        >
          {render({
            name,
            error: !!(get(form.touched, name) && get(form.errors, name)),
            ...field,
          })}
        </BronsonReactFormField>
      )}
    </Field>
  )
}

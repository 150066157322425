import { createStore, combineReducers } from 'redux'

import formReducer from './features/form'
import dataStorageReducer from './features/dataStorage'
import pageReducer from './features/page'
import productReducer from './features/product'
import keycloakReducer from './features/keycloak'

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    dataStorage: dataStorageReducer,
    page: pageReducer,
    product: productReducer,
    keycloak: keycloakReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

export { store }

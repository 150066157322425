export const SET_ACTUAL_PAGE = 'page//SET_ACTUAL_PAGE'
export const SET_LANDING_PAGE = 'page//SET_LANDING_PAGE'

export function setActualPage(data) {
  return {
    type: SET_ACTUAL_PAGE,
    payload: data,
  }
}

export function setLandingPage(payload) {
  return {
    type: SET_LANDING_PAGE,
    payload,
  }
}

export function getActualPage(store) {
  return store.page.actualPage
}

export function getLandingPage(store) {
  return store.page.landingPage
}

const initialState = {
  actualPage: '',
  landingPage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTUAL_PAGE:
      return {
        ...state,
        actualPage: action.payload,
      }

    case SET_LANDING_PAGE:
      return {
        ...state,
        landingPage: action.payload,
      }

    default:
      return state
  }
}

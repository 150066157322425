/*
 * Validations to share with frontend and backend
 *
 * Changes should be updated to both frontend and backend.
 * The validations should work with copy&paste for the most parts.
 * The only exception is ibanValidation that uses an asnyc check.
 * The check needs to be implemented separately.
 *
 * NOTE: Can't separate the i18n from these easilly beacuse of how the
 * forms work. Need to replace the messages in the backend to static
 * strings.
 */
import { differenceInYears, isFuture } from 'date-fns'
import i18n from 'i18next'
import * as Yup from 'yup'

import moment from 'moment'

const minDateSubstract = 156
const initialRegistrationMinDate = moment()
  .subtract(minDateSubstract, 'months')
  .toDate()

const getRegistrationDateErrorMsg = () => {
  return i18n.t('validation:regOlder13Years')
}

export const SEPA_REGEX = /^(?:((?:IT|SM)\d{2}[A-Z]{1}\d{22})|(NL\d{2}[A-Z]{4}\d{10})|(LV\d{2}[A-Z]{4}\d{13})|((?:BG|GB|IE)\d{2}[A-Z]{4}\d{14})|(GI\d{2}[A-Z]{4}\d{15})|(RO\d{2}[A-Z]{4}\d{16})|(MT\d{2}[A-Z]{4}\d{23})|(NO\d{13})|((?:DK|FI)\d{16})|((?:SI)\d{17})|((?:AT|EE|LU|LT)\d{18})|((?:HR|LI|CH)\d{19})|((?:DE|VA)\d{20})|((?:AD|CZ|ES|MD|SK|SE)\d{22})|(PT\d{23})|((?:IS)\d{24})|((?:BE)\d{14})|((?:FR|MC|GR)[A-Z0-9]{25})|((?:PL|HU|CY)\d{26}))$/
export const IBAN_REGEX = /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/
export const dateSeperator = '/'
export const dateFormat = 'DD/MM/YYYY'

Yup.addMethod(Yup.string, 'equalEmails', function equalEmails(field, message) {
  return this.test('equalEmail', message, function equalEmail(value) {
    return this.parent[field] === undefined || value === this.parent[field]
  })
})

Yup.addMethod(Yup.string, 'fullAge', function fullAge(years, message) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        differenceInYears(
          new Date(),
          new Date(date[2], date[1] - 1, date[0])
        ) >= years
      )
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'notInFuture', function notInFuture(message) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return !isFuture(new Date(date[2], date[1] - 1, date[0]))
    }
    return true
  })
})

export const customerFirstNameValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    i18n.t('validation:invalidFirstName')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(80, i18n.t('validation:maxLength', { value: 80 }))
  .required(i18n.t('validation:required'))

export const customerLastNameValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    i18n.t('validation:invalidLastName')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(80, i18n.t('validation:maxLength', { value: 80 }))
  .required(i18n.t('validation:required'))

export const customerDateOfBirthValidation = Yup.string()
  .notInFuture(i18n.t('validation:notInfuture'))
  .fullAge(18, i18n.t('validation:fullAge'))
  .required(i18n.t('validation:required'))

export const customerCountryOfBirthValidation = Yup.string().required(
  i18n.t('validation:required')
)

export const customerBirthDepartmentValidation = Yup.string()
  .matches(/^[0-9]{4,9}$/, i18n.t('validation:invalidPostCode'))
  .required(i18n.t('validation:required'))

export const customerPlaceOfBirthValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    i18n.t('validation:invalidField')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(80, i18n.t('validation:maxLength', { value: 80 }))
  .required(i18n.t('validation:required'))

export const customerSalutationValidation = Yup.string().required(
  i18n.t('validation:required')
)

export const customerStreetValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'0-9][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'0-9]*$/,
    i18n.t('validation:invalidStreet')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(100, i18n.t('validation:maxLength', { value: 100 }))
  .required(i18n.t('validation:required'))

export const customerHouseNumberValidation = Yup.string()
  .matches(
    /^[0-9a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\s0-9a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    { message: i18n.t('validation:invalidField'), excludeEmptyString: true }
  )
  .max(10, i18n.t('validation:maxLength', { value: 10 }))

export const customerAddressField1Validation = Yup.string()
  .matches(
    /^[0-9a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\s0-9a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    { message: i18n.t('validation:invalidField'), excludeEmptyString: true }
  )
  .max(100, i18n.t('validation:maxLength', { value: 100 }))

export const customerAddressField2Validation = Yup.string()
  .matches(
    /^[0-9a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\s0-9a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    { message: i18n.t('validation:invalidField'), excludeEmptyString: true }
  )
  .max(100, i18n.t('validation:maxLength', { value: 100 }))

export const customerCityValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    i18n.t('validation:invalidCity')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(50, i18n.t('validation:maxLength', { value: 50 }))
  .required(i18n.t('validation:required'))

export const customerPostalCodeValidation = Yup.string()
  .matches(/^[0-9]{5}$/, i18n.t('validation:invalidPostCode'))
  .required(i18n.t('validation:required'))

export const customerTelephoneNrValidation = Yup.string()
  .required(i18n.t('validation:required'))
  .matches(/^[0-9]+$/, i18n.t('validation:invalidPhone'))
  .min(9, i18n.t('validation:invalidPhone'))

export const customerPhoneCountryCodeValidation = Yup.string().required(
  i18n.t('validation:required')
)

export const customerPhoneCountryCode = Yup.string().required(
  i18n.t('validation:required')
)
export const customerEmailValidation = Yup.string()
  .email(i18n.t('validation:invalidEmail'))
  .required(i18n.t('validation:required'))

export const customerEmailConfirmationValidation = Yup.string()
  .email(i18n.t('validation:invalidEmail'))
  .when('customerEmail', {
    is: val => val === undefined || val.length,
    then: Yup.string().equalEmails(
      'customerEmail',
      i18n.t('validation:equalEmails')
    ),
  })
  .required(i18n.t('validation:required'))

export const licensePlateValidation = Yup.string()
  .min(9, i18n.t('validation:invalidField_license'))
  .required(i18n.t('validation:req_license'))

export const currentMileageValidation = Yup.number()
  .min(1, i18n.t('validation:invalidField'))
  .max(220000, i18n.t('validation:currentMileageTooHigh'))
  .required(i18n.t('validation:required'))

export const agreeDataProtection = Yup.boolean()
  .oneOf([true], i18n.t('validation:required'))
  .required(i18n.t('validation:required'))

export const tanAuthentificationValidation = Yup.string()
  .matches(/^[0-9]{6}$/, i18n.t('validation:invalidField'))
  .required(i18n.t('validation:required'))

export const ModelValidation = Yup.string().required(
  i18n.t('validation:required')
)

export const EngineValidation = Yup.string().required(
  i18n.t('validation:required')
)

export const initialRegistrationDateValidation = Yup.string()
  .test('notInFuture', i18n.t('validation:dateIsInFuture'), function testFuture(
    value
  ) {
    if (value) return moment(value, 'DD/MM/YYYY').isBefore()
    return true
  })
  .test(
    'registrationDateTooOld',
    getRegistrationDateErrorMsg(),
    function testFuture(value) {
      if (!value) return true
      return moment(value, 'DD/MM/YYYY').isAfter(initialRegistrationMinDate)
    }
  )
  .required(i18n.t('validation:required'))

export const communicateByEmail = tls => {
  if (tls) {
    return Yup.boolean().oneOf([true], i18n.t('validation:required'))
  }
  return null
}

export const employeeFirstNameValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    i18n.t('validation:invalidFirstName')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(80, i18n.t('validation:maxLength', { value: 80 }))
  .required(i18n.t('validation:required'))

export const employeeLastNameValidation = Yup.string()
  .matches(
    /^[a-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.'][\sa-zA-ZÄÅÀáâäàãåÉÈéêëèíîïìÖÒóôöòõÜÙúüûùÇçÑñŠšß\-.']*$/,
    i18n.t('validation:invalidLastName')
  )
  .min(2, i18n.t('validation:minLength', { value: 2 }))
  .max(80, i18n.t('validation:maxLength', { value: 80 }))
  .required(i18n.t('validation:required'))

const prefix = brandName => {
  switch (brandName) {
    case 'seat':
      return '1200'
    case 'cupra':
      return '1200'
    case 'skoda':
      return '1500'
    default:
      return '0100'
  }
}

const rexExp = new RegExp(`^${prefix(process.env.REACT_APP_BRAND)}[0-9]*`)

export const dealerCodeValidation = Yup.string()
  .matches(
    rexExp,
    i18n.t('validation:invalidCodeDealer', {
      code: prefix(process.env.REACT_APP_BRAND),
    })
  )
  .min(
    8,
    i18n.t('validation:invalidCodeDealer', {
      code: prefix(process.env.REACT_APP_BRAND),
    })
  )
  .max(
    8,
    i18n.t('validation:invalidCodeDealer', {
      code: prefix(process.env.REACT_APP_BRAND),
    })
  )
  .required(i18n.t('validation:required'))

export const dealerEmailValidation = Yup.string()
  .email(i18n.t('validation:invalidEmail'))
  .required(i18n.t('validation:required'))

import { axios } from '.'
import { getServiceBrandName } from '../brand'

const {
  REACT_APP_COUNTRYCODE,
  REACT_APP_PRODUCT,
  REACT_APP_ENTRYPOINT,
} = process.env

export function generateTan(transactionId) {
  const url = `/tan/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}/${transactionId}/generate`
  return axios
    .post(
      url,
      {},
      {
        validateStatus: statusCode => statusCode === 200,
      }
    )
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function generateTanInit(transactionId) {
  const url = `/tan/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}/${transactionId}/init`
  return axios
    .post(
      url,
      {},
      {
        validateStatus: statusCode => statusCode === 200,
      }
    )
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

import merge from 'lodash/merge'
import { digitalData } from './datalayer'

export const DATA_LAYER = 'du_digitalData'

/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 */
export class AnalyticsService {
  /** Shared analytics event data */
  default

  /** Events cannot be sent until _satellite is loaded. Calls to
   *  track happening before this will be deferred and sent in
   *  order as soon as possible (retry every 3 seconds). This is an
   *  array. Each item is an { type: ..., data: ... } object.
   */
  deferredEvents = []

  /** A setInterval handle in use when deferred sending is activated.
   */
  deferredEventInterval = null

  delay = 1000

  constructor() {
    this.default = merge({}, digitalData)
  }

  /**
   * Update global default analytics data for future calls
   *
   * Used to set fields in default data that are available
   * asynchronously after journey start or set during the journey.
   * @param {*} obj
   */
  updateDefault(obj) {
    // Mutates default.
    merge(this.default, obj)
  }

  /**
   * Set data and send track event
   * @param {*} event Analytics event
   */
  track(event) {
    // Merge to empty object so that default is not mutated.
    const updatedData = merge({}, this.default, event.data)

    // eslint-disable-next-line no-underscore-dangle
    const satellite = window._satellite

    if (satellite) {
      AnalyticsService.setDataLayer(updatedData)
      AnalyticsService.sendTrackEvent(event.type)
    }
  }

  processDeferredEvents() {
    // eslint-disable-next-line no-underscore-dangle
    const satellite = window._satellite
    if (satellite) {
      if (this.deferredEvents.length > 0) {
        // note, events are sent one at a time as otherwise
        //   the window[DATA_LATER] is overwritten with the last event data
        //   before anything is sent
        const [thisEvent, ...remainingEvents] = this.deferredEvents
        AnalyticsService.setDataLayer(thisEvent.data)
        AnalyticsService.sendTrackEvent(thisEvent.type)
        this.deferredEvents = remainingEvents
      }

      if (
        this.deferredEventInterval != null &&
        this.deferredEvents.length === 0
      ) {
        // when all deferred events were sent we no
        //   longer need the interval to be active
        clearInterval(this.deferredEventInterval)
        this.deferredEventInterval = null
      }
    }
    // else do nothing yet, still waiting for _satellite
  }

  /**
   * Send a tracking event
   *
   * Triggers a tracking event to send analytics data. Data layer
   * should be set before calling.
   *
   * @param {string} type Track event type
   */
  static sendTrackEvent(type) {
    // eslint-disable-next-line no-underscore-dangle
    const satellite = window._satellite
    if (satellite && satellite.track) {
      satellite.track(type)
    } else {
      // eslint-disable-next-line no-console
      console.error('No Analytics Satellite Object')
    }
  }

  static setDataLayer(data) {
    window[DATA_LAYER] = data
  }
}

import Keycloak from 'keycloak-js'
import { getBrandName } from '../../brand/index'

const scope =
  'profile email phone firstName lastName fullPhoneNumber dateOfBirth'

const url = process.env.REACT_APP_IDP_URL || ''

const BrandName = getBrandName()

const getBrandedClientId = brand => {
  let clientId = 'custPortalOspfrVw'
  switch (brand) {
    case 'audi':
      clientId = 'custPortalOspfrAudi'
      break
    case 'seat':
      clientId = 'custPortalOspfrSeat'
      break
    case 'cupra':
      clientId = 'custPortalOspfrCupra'
      break
    case 'skoda':
      clientId = 'custPortalOspfrSkoda'
      break
    case 'vw':
      clientId = 'custPortalOspfrVw'
      break
    default:
      clientId = 'custPortalOspfrVw'
      break
  }
  return clientId
}

const getBrandedIdpHint = brand => {
  return `idkospfrvwfs${brand}`
}

const keycloakConfig = {
  vwfs: {
    realm: 'vwfs-du-ospfr',
    clientId: getBrandedClientId(BrandName),
    url,
    scope,
  },
  audi: {
    realm: 'vwfs-du-ospfr',
    clientId: 'custPortalOspfrAudi',
    url,
    scope,
  },
  seat: {
    realm: 'vwfs-du-ospfr',
    clientId: 'custPortalOspfrSeat',
    url,
    scope,
  },
  cupra: {
    realm: 'vwfs-du-ospfr',
    clientId: 'custPortalOspfrCupra',
    url,
    scope,
  },
  skoda: {
    realm: 'vwfs-du-ospfr',
    clientId: 'custPortalOspfrSkoda',
    url,
    scope,
  },
  vw: {
    realm: 'vwfs-du-ospfr',
    clientId: 'custPortalOspfrVw',
    url,
    scope,
  },
}

const keycloakClient = {
  vwfs: {
    name: 'vwfs',
    idpHint: getBrandedIdpHint(BrandName),
    kc: new Keycloak(keycloakConfig.vwfs),
  },
  audi: {
    name: 'audi',
    idpHint: 'idkospfraudiaudi',
    kc: new Keycloak(keycloakConfig.audi),
  },
  seat: {
    name: 'seat',
    idpHint: 'idkospfrseatseat',
    kc: new Keycloak(keycloakConfig.seat),
  },
  cupra: {
    name: 'cupra',
    idpHint: 'idkospfrcupracupra',
    kc: new Keycloak(keycloakConfig.cupra),
  },
  skoda: {
    name: 'skoda',
    idpHint: 'idkospfrskodaskoda',
    kc: new Keycloak(keycloakConfig.skoda),
  },
  vw: {
    name: 'vw',
    idpHint: 'idkospfrvwvw',
    kc: new Keycloak(keycloakConfig.vw),
  },
}

export default keycloakClient

import { axios, setAxiosIdToken, setAxiosToken } from '..'

const { REACT_APP_API_SERVER_URL } = process.env

export function createTransaction() {
  const url = `${REACT_APP_API_SERVER_URL}/generate-token-transactionId`
  setAxiosToken(null)
  setAxiosIdToken(null)
  return axios
    .post(url, {
      validateStatus: statusCode => statusCode === 200,
    })
    .then(response => {
      if (response.data.transactionId && response.data.token) {
        setAxiosToken(response.data.token)
        setAxiosIdToken(response.data.transactionId)
        return Promise.resolve(response.data)
      }
      return Promise.reject()
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const getTransactionStatus = step => {
  const STATUS = {
    1: transactionStatus.INITIALIZED,
    2: transactionStatus.APPLICATIONFORM_STARTED,
    3: transactionStatus.APPLICATIONFORM_COMPLETED,
    5: transactionStatus.CONTRACT_CONFIRMED,
  }
  return STATUS[step]
}

export const transactionStatus = {
  INITIALIZED: 'INITIALIZED',
  APPLICATIONFORM_STARTED: 'APPLICATIONFORM_STARTED',
  APPLICATIONFORM_COMPLETED: 'APPLICATIONFORM_COMPLETED',
  ESIGN_READY: 'ESIGN_READY',
  CONTRACT_CONFIRMED: 'CONTRACT_CONFIRMED',
  LEAD_SUBMITTED: 'LEAD_SUBMITTED',
  ESIGN_REFUSED: 'ESIGN_REFUSED',
}

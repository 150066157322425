import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { Router, withRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import './services/i18n'
import { history } from './services/routing'
import { store } from './services/redux'

import KeycloakWrapper from './KeycloakWrapper'

const PositionRestore = withRouter(props => {
  const [location, setLocation] = React.useState(props.location.pathname)
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }
  return null
})

function Init() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <PositionRestore />
        <React.Fragment>
          <KeycloakWrapper />
        </React.Fragment>
      </Router>
    </Provider>
  )
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  ReactDOM.render(<Init />, rootElement)
}

render()

import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Spinner } from 'cj-common-components'
import { connect, useDispatch } from 'react-redux'
import { isDev } from './services/common/data'
import {
  setKeycloakIdp as setKeycloakIdpAction,
  setKeycloakIdpHint as setKeycloakIdpHintAction,
  getKeycloakIdp,
} from './services/redux/features/keycloak'
import { App } from './App'
import { history, qs } from './services/routing'
import keycloakClient from './services/api/login/keycloak'
import { generateTanInit } from './services/api/tan'
import {
  resetFormData as setTransactionId,
  setFormData,
} from './services/redux/features/form'
import { setAxiosIdToken, setAxiosToken } from './services/api'
import { mapBusModelToFrontendModel } from './services/data/mapping'
import { done } from './services/redux/features/dataStorage'

const Loading = () => (
  <div className="u-text-center">
    <br />
    <br />
    <Spinner center />
    <br />
    <br />
  </div>
)

function KeycloakWrapper(props) {
  const brandName = process.env.REACT_APP_BRAND

  const { keycloakIdp } = props
  const dispatch = useDispatch()

  const [keycloakId, setKeycloakId] = React.useState('')
  const keycloakLoginId = qs(document.location.search, 'loginId')
  const keycloakLogoutId = qs(document.location.search, 'logoutId')
  const keycloak = React.useMemo(() => {
    return keycloakIdp === 'vwfs'
      ? keycloakClient.vwfs.kc
      : keycloakClient[brandName].kc
  }, [keycloakIdp, brandName])
  React.useEffect(() => {
    if (keycloakLoginId && keycloak.authenticated) {
      setKeycloakId(keycloakLoginId)
    }
    if (keycloakLogoutId && !keycloak.authenticated) {
      setKeycloakId(keycloakLogoutId)
    }
  }, [keycloakLogoutId, keycloakLoginId, keycloak.authenticated])
  const [isKeycloakReady, setIsKeycloakReady] = React.useState(false)
  const [isReady, setIsReady] = React.useState(false)
  React.useEffect(() => {
    if (keycloakId) {
      dispatch(setTransactionId(keycloakId))
      generateTanInit(keycloakId)
        .then(response => {
          setAxiosIdToken(response.response.nonce)
          setAxiosToken(response.response.token)
          dispatch(done(response.data.data))
          dispatch(
            setFormData({
              ...mapBusModelToFrontendModel(response.data.data),
              transactionId: keycloakId,
            })
          )
          setIsReady(true)
          history.replace({
            search: '',
          })
        })
        .catch(() => history.push('/error'))
    }
  }, [keycloakId, dispatch])

  React.useEffect(() => {
    setIsReady(!keycloakId)
  }, [keycloakId])

  const eventLogger = (event, error) => {
    if (isDev()) {
      // eslint-disable-next-line no-console
      console.log('onKeycloakEvent', event, error)
    }
    if (event === 'onReady' || event === 'onInitError') {
      setIsKeycloakReady(true)
    }
  }

  const tokenLogger = tokens => {
    if (isDev()) {
      // eslint-disable-next-line no-console
      console.log('onKeycloakTokens', tokens)
    }
  }

  const logout = () => {
    history.replace({
      search: '',
    })
    const redirectUri =
      `${window.origin}/` === window.location.href
        ? window.origin
        : `${window.location.href}?logoutId=${keycloakId}`
    keycloak.logout({
      redirectUri,
    })
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      {isReady && isKeycloakReady ? (
        <App keycloak={keycloak} keycloakLogout={logout} />
      ) : (
        <Loading />
      )}
    </ReactKeycloakProvider>
  )
}

export default connect(
  state => ({
    keycloakIdp: getKeycloakIdp(state),
  }),
  dispatch => ({
    setKeycloakIdp: value => dispatch(setKeycloakIdpAction(value)),
    setKeycloakIdpHint: value => dispatch(setKeycloakIdpHintAction(value)),
  })
)(KeycloakWrapper)

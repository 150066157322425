export const getBrandName = () => {
  if (
    typeof window.reactConfig === 'object' &&
    window.reactConfig.brand !== '%brand%'
  ) {
    return window.reactConfig.brand
  }
  return process.env.REACT_APP_BRAND
}

export const getServiceBrandName = () => {
  return getBrandName().toLowerCase()
}

export const mapperBrand = brand => {
  switch (brand.toLowerCase()) {
    case 'volkswagen':
      return 'vw'

    default:
      return brand.toLowerCase()
  }
}

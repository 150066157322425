import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { formatIban } from '../api/iban'

export const dataChanged = (dataStorage, journeyData) => {
  return !isEqual(
    mapFrontendModelToBftModel(
      journeyData.stepData,
      journeyData.transactionStatus
    ),
    dataStorage
  )
}

export const mapBusModelToFrontendModel = dataStorage => {
  const frontendData = {
    transactionStatus: dataStorage.transaction?.status,
    stepData: {
      '1': {
        vin: dataStorage.vehicleData?.vin || '',
        licensePlate: dataStorage.vehicleData?.licensePlate || '',
        initialRegistrationDate:
          dataStorage.vehicleData?.initialRegistrationDate || '',
        model: {
          manufacturer: dataStorage.vehicleData?.model?.manufacturer || '',
          name: dataStorage.vehicleData?.model?.name || '',
          engine: {
            fuelType: dataStorage.vehicleData?.model?.engine?.fuelType || '',
          },
          pricingId: dataStorage.vehicleData?.model?.pricingId || '',
        },
        contractDuration:
          dataStorage.financialProduct?.calculation?.duration || null,
        contractMileage:
          dataStorage.financialProduct?.calculation?.yearlyMileage || null,
        contractPlan: dataStorage.financialProduct?.servicePlan || '',
        monthlyPrice:
          dataStorage.financialProduct?.pricing?.monthlyPrice || '-',
        upfrontPrice:
          dataStorage.financialProduct?.pricing?.upfrontPrice || '-',
        selectedPrice:
          (dataStorage.customerData?.paymentData?.paymentDecision === 'upfront'
            ? dataStorage.financialProduct?.pricing?.upfrontPrice
            : dataStorage.financialProduct?.pricing?.monthlyPrice) || '-',
        paymentDecision:
          dataStorage.customerData?.paymentData?.paymentDecision || 'monthly',
        isUsed: dataStorage.vehicleData?.isUsed || false,
        currentMileage: dataStorage.vehicleData?.odometer || '',
      },
      '2': {
        customerAcceptEmail:
          dataStorage.transaction?.dataPrivacyStatement?.allowMailAds || false,
        customerAcceptPartnerAds:
          dataStorage.transaction?.dataPrivacyStatement
            ?.customerAcceptPartnerAds || false,
        dataPrivacyConsentAcceptanceSaveAndContinue:
          dataStorage.transaction?.dataPrivacyStatement
            ?.dataPrivacyConsentAcceptanceSaveAndContinue || false,
        customerAddressField1:
          dataStorage.customerData?.addresses?.[0]?.optionalLine ||
          // eslint-disable-next-line max-len
          dataStorage.customerData?.address?.optionalLine || // the alternative ones are used when coming from an external app via the product-routing-service
          '',
        customerAddressField2:
          dataStorage.customerData?.addresses?.[0]?.optionalLine2 ||
          dataStorage.customerData?.address?.optionalLine2 ||
          '',
        customerCity:
          dataStorage.customerData?.addresses?.[0]?.city ||
          dataStorage.customerData?.address?.city ||
          '',
        customerCountry:
          dataStorage.customerData?.addresses?.[0]?.country ||
          dataStorage.customerData?.address?.country ||
          '',
        customerEmail: dataStorage.customerData?.contactData?.email || '',
        customerEmailConfirmation:
          dataStorage.customerData?.contactData?.email || '',
        customerFirstName: dataStorage.customerData?.firstName || '',
        customerHouseNumber:
          dataStorage.customerData?.addresses?.[0]?.houseNumber ||
          dataStorage.customerData?.address?.houseNumber ||
          '',
        customerLastName: dataStorage.customerData?.lastName || '',
        customerPostalCode:
          dataStorage.customerData?.addresses?.[0]?.zipCode ||
          dataStorage.customerData?.address?.zipCode ||
          '',
        customerSalutation:
          dataStorage.customerData?.salutation?.toLowerCase() || '',
        customerStreet:
          dataStorage.customerData?.addresses?.[0]?.street ||
          dataStorage.customerData?.address?.street ||
          '',
        customerTelephoneNr: setPhoneNumber(
          dataStorage.customerData?.contactData?.mobilePhoneNumber,
          dataStorage.customerData?.contactData?.mobilePhoneCountryCode
        ),
        customerPhoneCountryCode:
          dataStorage.customerData?.contactData?.mobilePhoneCountryCode || '',
        customerDateOfBirth: parseDate(dataStorage.customerData?.dateOfBirth),
        customerPlaceOfBirth: dataStorage.customerData?.placeOfBirth || '',
        customerBirthDepartment:
          dataStorage.customerData?.customerBirthDepartment ||
          dataStorage.customerData?.stateOfBirth ||
          '',
      },
      '3': {
        paymentAccepted:
          dataStorage.customerData?.paymentData?.paymentAccepted || false,
        bic: dataStorage.customerData?.paymentData?.bic || '',
        payDayInMonth:
          dataStorage.customerData?.paymentData?.payDayInMonth || '',
        iban: dataStorage.customerData?.paymentData?.iban || '',
        termsAndConditionsAccepted:
          dataStorage.customerData?.paymentData?.termsAndConditionsAccepted ||
          false,
        dataPrivacyConsentAcceptance:
          dataStorage.transaction?.dataPrivacyStatement
            ?.dataPrivacyConsentAcceptance || false,
      },
    },
  }
  return frontendData
}

export const mapFrontendModelToBftModel = (
  stepData,
  transactionStatus = ''
) => {
  let fesData = {
    transaction: {
      status: transactionStatus,
      ...(stepData['2'] && {
        dataPrivacyStatement: {
          allowMailAds:
            stepData['2']?.customerAcceptEmail ||
            !!stepData['3']?.customerAcceptEmail,
          dataPrivacyConsentAcceptance:
            stepData['3']?.dataPrivacyConsentAcceptance,
          dataPrivacyConsentAcceptanceSaveAndContinue:
            stepData['2']?.dataPrivacyConsentAcceptanceSaveAndContinue,
          allowUnencryptedEmails: stepData['2'].communicateByEmailConfirmation,
          allowUnencryptedEmailsDate: stepData['2']
            .communicateByEmailConfirmation
            ? new Date().toISOString()
            : '',
          customerAcceptPartnerAds: stepData['2']?.customerAcceptPartnerAds,
        },
      }),
    },
    ...(stepData['2'] && {
      customerData: {
        firstName: stepData['2']?.customerFirstName,
        lastName: stepData['2']?.customerLastName,
        salutation: stepData['2']?.customerSalutation,
        dateOfBirth: stepData['2']?.customerDateOfBirth,
        placeOfBirth: stepData['2']?.customerPlaceOfBirth,
        customerBirthDepartment: stepData['2']?.customerBirthDepartment,
        addresses: [
          {
            houseNumber: stepData['2']?.customerHouseNumber,
            street: stepData['2']?.customerStreet,
            optionalLine: stepData['2']?.customerAddressField1,
            optionalLine2: stepData['2']?.customerAddressField2,
            zipCode: stepData['2']?.customerPostalCode,
            city: stepData['2']?.customerCity,
            country: stepData['2']?.customerCountry,
          },
        ],
        contactData: {
          email: stepData['2']?.customerEmail,
          mobilePhoneNumber: getPhoneNumber(
            stepData['2']?.customerTelephoneNr,
            stepData['2']?.customerPhoneCountryCode
          ),
          mobilePhoneCountryCode: stepData['2']?.customerPhoneCountryCode,
        },
        ...(stepData['3'] && {
          paymentData: {
            iban: formatIban(stepData['3']?.iban),
            bic: stepData['3']?.bic,
            paymentAccepted: stepData['3']?.paymentAccepted,
            termsAndConditionsAccepted:
              stepData['3']?.dataPrivacyConsentAcceptance,
            payDayInMonth: stepData['3']?.payDayInMonth,
          },
        }),
      },
    }),
    ...(stepData['1']?.contractPlan && {
      financialProduct: {
        servicePlan: stepData['1']?.contractPlan,
        calculation: {
          duration: stepData['1']?.contractDuration,
          yearlyMileage: stepData['1']?.contractMileage,
        },
        plan: {
          ...stepData['1']?.plan,
        },
      },
    }),
    ...(stepData['1'] && {
      vehicleData: {
        vin: stepData['1']?.vin,
        licensePlate: stepData['1']?.licensePlate,
        initialRegistrationDate: stepData['1']?.initialRegistrationDate,
        odometer: stepData['1']?.currentMileage,
        model: {
          manufacturer: stepData['1']?.model?.manufacturer,
          name: stepData['1']?.model?.name,
          engine: {
            fuelType: stepData['1']?.model?.engine?.fuelType,
          },
          pricingId: stepData['1']?.model?.pricingId,
        },
        isUsed: stepData['1']?.isUsed,
      },
    }),
  }

  fesData = {
    ...fesData,
    customerData: {
      ...fesData?.customerData,
      paymentData: {
        ...fesData?.customerData?.paymentData,
        paymentDecision: stepData['1']?.paymentDecision,
      },
    },
  }

  return removeEmptyValues(fesData)
}

export const removeEmptyValues = obj => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmptyValues(val)) ||
      // eslint-disable-next-line no-param-reassign
      ((val === null || val === '' || val === undefined) && delete obj[key])
  )
  return obj
}

const getPhoneNumber = (phoneNumber, countryCode) => {
  let result = phoneNumber
  if (countryCode === '+33' && result && result.startsWith('0')) {
    result = result.substring(1)
  }
  return result
}

const setPhoneNumber = (phoneNumber, countryCode) => {
  let result = phoneNumber
  if (countryCode === '+33' && result && result[0] !== '0') {
    result = `0${result}`
  }
  return result || ''
}

const parseDate = date => {
  if (!date) {
    return ''
  }
  const isoParsed = moment.utc(date, 'YYYY-MM-DD', true)
  return isoParsed.isValid() ? isoParsed.format('DD/MM/YYYY') : date
}

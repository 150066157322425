import _axios from 'axios'
import { Analytics, AnalyticsEvent } from '../analytics'

export const axios = _axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
})
let domain = ''
const getDomain = error => {
  try {
    return error.config.url.split('/')[1]
  } catch (e) {
    return null
  }
}

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    setTimeout(() => {
      if (window.location.href.indexOf('error') === -1) {
        return null
      }
      const newDomain = getDomain(error)
      if (domain === newDomain) {
        return Promise.reject(error)
      }
      domain = newDomain

      switch (error?.response?.status) {
        case 401:
          Analytics.track(
            new AnalyticsEvent.ErrorPage401(error.response.request.responseURL)
          )
          break
        case 403:
          Analytics.track(
            new AnalyticsEvent.ErrorPage403(error.response.request.responseURL)
          )
          break
        case 404:
          Analytics.track(
            new AnalyticsEvent.ErrorPage404(error.response.request.responseURL)
          )
          break
        case 500:
          Analytics.track(
            new AnalyticsEvent.ErrorPage500(
              error.response.data.message,
              error.response.request.responseURL
            )
          )
          break
        default:
          Analytics.track(
            new AnalyticsEvent.ErrorPageAPI(
              error.response.request.responseURL,
              error.response.status,
              error.response?.data?.message ?? ''
            )
          )
          break
      }
      return null
    }, 1000)
    return Promise.reject(error)
  }
)

export const setAxiosToken = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token
  } else {
    delete axios.defaults.headers.common['x-auth-token']
  }
}

export const getAxiosToken = () => axios.defaults.headers.common['x-auth-token']

export const setAxiosIdToken = id => {
  if (id) {
    axios.defaults.headers.common['x-id-token'] = id
  } else {
    delete axios.defaults.headers.common['x-id-token']
  }
}

const filenameCGU = 'CGU.pdf'
const filenameUsedMaintenance = "Condition Générales de l'Entretien.pdf"

// GENERAL CONDITIONS OF THE ‘MAINTENANCE AND WEAR PARTS’
// CONTRACT FOR NEW VEHICLES
const brandToFilenameMaintenancePlus = {
  audi: 'ServiceOnline_CG EntretienPU_VN2403_Audi.pdf',
  cupra: 'ServiceOnline_CG EntretienPU_VN2403_CUPRA.pdf',
  seat: 'ServiceOnline_CG EntretienPU_VN2403_SEAT.pdf',
  skoda: 'ServiceOnline_CG EntretienPU_VN2403_SKODA.pdf',
  vw: 'ServiceOnline_CG EntretienPU_VN2403_VW.pdf',
}

// GENERAL TERMS AND CONDITIONS OF THE ‘MAINTENANCE’
// CONTRACT FOR PRIVATE INDIVIDUALS
const brandToFilenameMaintenance = {
  audi: 'ServiceOnline_CG Entretien_VN2403_Audi.pdf',
  cupra: 'ServiceOnline_CG Entretien_VN2403_CUPRA.pdf',
  seat: 'ServiceOnline_CG Entretien_VN2403_SEAT.pdf',
  skoda: 'ServiceOnline_CG Entretien_VN2403_SKODA.pdf',
  vw: 'ServiceOnline_CG Entretien_VN2403_VW.pdf',
}

export {
  filenameCGU,
  filenameUsedMaintenance,
  brandToFilenameMaintenance,
  brandToFilenameMaintenancePlus,
}
